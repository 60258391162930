/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import { useIsPreviewing } from '@builder.io/react';
import { loadable } from '@fiverr-private/perseus';
import VideoConfig from '../../../../docs/builderMediaComponents/Video.json';
import { VERSIONS } from '../../../../constants';
import { source, videoEventMetadata } from './helpers/inputs';
import VideoEvent from './helpers/VideoEvent';

const withVideoEvents = (WrappedComponent) => (props) => {
    const { eventMetadata = {}, autoPlay, subtitles } = props;
    const isPreviewing = useIsPreviewing();
    if (Object.keys(eventMetadata).length && !isPreviewing) {
        return (
            <VideoEvent eventMetadata={eventMetadata} autoPlay={autoPlay} subtitles={subtitles}>
                <WrappedComponent {...props} />
            </VideoEvent>
        );
    }

    return <WrappedComponent {...props} />;
};

const Video = loadable(() => import(/* webpackChunkName: "VideoV2" */ './video'));
Video.displayName = 'Video';

const MEDIA_COMPONENTS_CONFIG = {
    components: [
        {
            element: Video,
            version: VERSIONS.V2,
            componentConfig: VideoConfig,
            hoc: withVideoEvents,
            manualInputs: [videoEventMetadata, source],
        },
    ],
    category: 'Media',
};

Video.displayName = 'Video';

export default MEDIA_COMPONENTS_CONFIG;
