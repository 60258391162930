import { loadable } from '@fiverr-private/perseus';
import SelectConfig from '../../../../docs/builderFormComponents/Select.json';
import OptionConfig from '../../../../docs/builderFormComponents/Option.json';
import InputGroupConfig from '../../../../docs/builderInputsComponents/InputGroup.json';
import InputConfig from '../../../../docs/builderInputsComponents/Input.json';
import InputPrefixConfig from '../../../../docs/builderInputsComponents/InputPrefix.json';
import InputSuffixConfig from '../../../../docs/builderInputsComponents/InputSuffix.json';
import CheckboxConfig from '../../../../docs/builderInputsComponents/Checkbox.json';
import CheckboxGroupConfig from '../../../../docs/builderInputsComponents/CheckboxGroup.json';
import RadioConfig from '../../../../docs/builderInputsComponents/Radio.json';
import RadioGroupConfig from '../../../../docs/builderInputsComponents/RadioGroup.json';
import SwitchConfig from '../../../../docs/builderInputsComponents/Switch.json';
import TextareaConfig from '../../../../docs/builderInputsComponents/Textarea.json';
import ChoiceGroupConfig from '../../../../docs/builderInputsComponents/ChoiceGroup.json';
import ChoiceGroupItemConfig from '../../../../docs/builderInputsComponents/ChoiceGroupItem.json';

const Select = loadable(() => import(/* webpackChunkName: "Select" */ './select'));
Select.displayName = 'Select';

const Option = loadable(() => import(/* webpackChunkName: "Select" */ './option'));
Option.displayName = 'Option';

const InputGroup = loadable(() => import(/* webpackChunkName: "Input" */ './inputGroup'));
InputGroup.displayName = 'InputGroup';

const Input = loadable(() => import(/* webpackChunkName: "Input" */ './input'));
Input.displayName = 'Input';

const InputPrefix = loadable(() => import(/* webpackChunkName: "Input" */ './inputPrefix'));
InputPrefix.displayName = 'InputPrefix';

const InputSuffix = loadable(() => import(/* webpackChunkName: "Input" */ './inputSuffix'));
InputSuffix.displayName = 'InputSuffix';

const CheckboxGroup = loadable(() => import(/* webpackChunkName: "Checkbox" */ './checkboxGroup'));
CheckboxGroup.displayName = 'CheckboxGroup';

const Checkbox = loadable(() => import(/* webpackChunkName: "Checkbox" */ './checkbox'));
Checkbox.displayName = 'Checkbox';

const RadioGroup = loadable(() => import(/* webpackChunkName: "Radio" */ './radioGroup'));
RadioGroup.displayName = 'RadioGroup';

const Radio = loadable(() => import(/* webpackChunkName: "Radio" */ './radio'));
Radio.displayName = 'Radio';

const Switch = loadable(() => import(/* webpackChunkName: "Switch" */ './switch'));
Switch.displayName = 'Switch';

const Textarea = loadable(() => import(/* webpackChunkName: "Textarea" */ './textarea'));
Textarea.displayName = 'Textarea';

const ChoiceGroup = loadable(() => import(/* webpackChunkName: "ChoiceGroup" */ './choiceGroup'));
ChoiceGroup.displayName = 'ChoiceGroup';

const ChoiceGroupItem = loadable(() => import(/* webpackChunkName: "ChoiceGroup" */ './choiceGroupItem'));
ChoiceGroupItem.displayName = 'ChoiceGroupItem';

const INPUTS_COMPONENTS_CONFIG = {
    components: [
        { element: Select, componentConfig: SelectConfig, manualOptions: { noWrap: false } },
        { element: Option, componentConfig: OptionConfig },
        { element: InputGroup, componentConfig: InputGroupConfig, manualOptions: { noWrap: false } },
        { element: Input, componentConfig: InputConfig },
        { element: InputPrefix, componentConfig: InputPrefixConfig },
        { element: InputSuffix, componentConfig: InputSuffixConfig },
        { element: CheckboxGroup, componentConfig: CheckboxGroupConfig, manualOptions: { noWrap: false } },
        { element: Checkbox, componentConfig: CheckboxConfig },
        { element: RadioGroup, componentConfig: RadioGroupConfig, manualOptions: { noWrap: false } },
        { element: Radio, componentConfig: RadioConfig },
        { element: Switch, componentConfig: SwitchConfig },
        { element: Textarea, componentConfig: TextareaConfig },
        { element: ChoiceGroup, componentConfig: ChoiceGroupConfig, manualOptions: { noWrap: false } },
        { element: ChoiceGroupItem, componentConfig: ChoiceGroupItemConfig },
    ],
    category: 'Inputs',
};

export default INPUTS_COMPONENTS_CONFIG;
