/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import { ImpressionEvent } from '../../../../components/ImpressionEvent';
import {
    MIXPANEL_COMPONENT_NAME,
    MIXPANEL_IMPRESSION_EVENT_NAME,
} from '../../../../components/EventReportingManager/constants';

const withImpression =
    (WrappedComponent) =>
    ({ eventName, reportingComponentName, eventEnrichment, ...props }) => {
        const componentName = reportingComponentName || WrappedComponent.displayName;

        return eventName ? (
            <ImpressionEvent
                context={{
                    bq: {
                        'page.element.type': componentName,
                        ...eventEnrichment,
                    },
                    mixpanel: {
                        [MIXPANEL_COMPONENT_NAME]: componentName,
                        [MIXPANEL_IMPRESSION_EVENT_NAME]: eventName,
                        ...eventEnrichment,
                    },
                }}
                eventName={eventName}
            >
                <WrappedComponent {...props} />
            </ImpressionEvent>
        ) : (
            <WrappedComponent {...props} />
        );
    };

export default withImpression;
