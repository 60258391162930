import { loadable } from '@fiverr-private/perseus';
import CollapsibleConfig from '../../../../docs/builderDisclosureComponents/Collapsible.json';
import CollapsibleGroupConfig from '../../../../docs/builderDisclosureComponents/CollapsibleGroup.json';
import CollapsibleHeaderConfig from '../../../../docs/builderDisclosureComponents/CollapsibleHeader.json';
import CollapsibleContentConfig from '../../../../docs/builderDisclosureComponents/CollapsibleContent.json';
import { VERSIONS, BUILDER_COMPONENT_TYPE, BUILDER_ELEMENT, DEFAULT_TEXT_COMPONENT } from '../../../../constants';

const Collapsible = loadable(() => import(/* webpackChunkName: "CollapsibleV2" */ './collapsible'));
Collapsible.displayName = 'Collapsible';

const CollapsibleGroup = loadable(() => import(/* webpackChunkName: "CollapsibleV2" */ './collapsibleGroup'));
CollapsibleGroup.displayName = 'CollapsibleGroup';

const CollapsibleHeader = loadable(() => import(/* webpackChunkName: "CollapsibleV2" */ './collapsibleHeader'));
CollapsibleHeader.displayName = 'CollapsibleHeader';

const CollapsibleContent = loadable(() => import(/* webpackChunkName: "CollapsibleV2" */ './collapsibleContent'));
CollapsibleContent.displayName = 'CollapsibleContent';

const DISCLOSURE_COMPONENTS_CONFIG = {
    components: [
        {
            element: Collapsible,
            componentConfig: CollapsibleConfig,
            version: VERSIONS.V2,
            manualOptions: {
                defaultChildren: [
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: DEFAULT_TEXT_COMPONENT,
                            },
                        ],
                        component: {
                            name: 'fiverr:CollapsibleHeader',
                        },
                    },
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: DEFAULT_TEXT_COMPONENT,
                            },
                        ],
                        component: {
                            name: 'fiverr:CollapsibleContent',
                        },
                    },
                ],
            },
        },
        {
            element: CollapsibleGroup,
            manualOptions: { noWrap: false },
            componentConfig: CollapsibleGroupConfig,
        },
        {
            element: CollapsibleHeader,
            componentConfig: CollapsibleHeaderConfig,
            manualOptions: { hideFromInsertMenu: true },
        },
        {
            element: CollapsibleContent,
            componentConfig: CollapsibleContentConfig,
            manualOptions: { hideFromInsertMenu: true },
        },
    ],
    category: 'Disclosure',
};

export default DISCLOSURE_COMPONENTS_CONFIG;
