import { get, isString } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';

const DYNAMIC_PARAMS_REGEX = /\${([a-zA-Z0-9._\-"'[\]]+)}/;

export const injectParamsToField = (field) => {
    if (!isString(field)) {
        return field;
    }

    const regex = new RegExp(DYNAMIC_PARAMS_REGEX, 'g');
    return field?.replace(regex, (match, p1) => get(getContext(), p1));
};
