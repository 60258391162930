import { loadable } from '@fiverr-private/perseus';
import { COMPONENT_PREFIX } from '../../../../constants';
import IconButtonConfig from '../../../../docs/builderButtonComponents/IconButton.json';

const SocialSharing = loadable(() => import(/* webpackChunkName: "SocialSharing" */ './socialSharing'));

const getIconButtonProp = (propName: string) =>
    IconButtonConfig.options.inputs.find((input) => {
        if (typeof input === 'object') {
            return input.name === propName;
        }
        return input === propName;
    });

const ICON_BUTTON_PROPS = {
    size: getIconButtonProp('size') as object,
    variant: getIconButtonProp('variant') as object,
    intent: getIconButtonProp('intent') as object,
    colorScheme: getIconButtonProp('colorScheme') as object,
};

const SOCIAL_SHARING_CONFIG = {
    components: [
        {
            component: SocialSharing,
            options: {
                name: `${COMPONENT_PREFIX}Social Sharing`,
                inputs: [
                    {
                        name: 'facebook',
                        type: 'boolean',
                        defaultValue: true,
                        required: false,
                    },
                    {
                        name: 'whatsapp',
                        type: 'boolean',
                        defaultValue: true,
                        required: false,
                    },
                    {
                        name: 'twitter',
                        type: 'boolean',
                        defaultValue: true,
                        required: false,
                    },
                    {
                        name: 'linkedin',
                        type: 'boolean',
                        defaultValue: true,
                        required: false,
                    },
                    {
                        name: 'email',
                        type: 'boolean',
                        defaultValue: true,
                        required: false,
                    },
                    {
                        name: 'copyLink',
                        type: 'boolean',
                        defaultValue: true,
                        required: false,
                    },
                    {
                        name: 'emailSubject',
                        type: 'string',
                        defaultValue: 'Check out this great Fiverr Post',
                        required: true,
                    },
                    {
                        name: 'whatsappText',
                        type: 'string',
                        defaultValue: 'Check out this link',
                        required: true,
                    },
                    { ...ICON_BUTTON_PROPS.size, defaultValue: 'sm' },
                    ICON_BUTTON_PROPS.intent,
                    { ...ICON_BUTTON_PROPS.variant, defaultValue: 'ghost' },
                    ICON_BUTTON_PROPS.colorScheme,
                ],
                noWrap: false,
            },
        },
    ],
};

export default SOCIAL_SHARING_CONFIG;
