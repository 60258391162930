import { useContext } from 'react';
import { merge } from 'lodash';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { renderChildren } from '@fiverr-private/ui_utils';
import { EventReportingManagerContext } from '../EventReportingManager';
import { convertContextToBQ, convertContextToMixpanel, sendMixpanelEvent } from '../EventReportingManager/utils';
import { injectParamsToField } from '../../utils/injectParamsToField';

const USER_IMPRESSIONS = 'user_impressions';
const IMPRESSION_ACTION = 'impression';
const IMPRESSION_EVENT_NAME = 'Builder LP - Component Imp';

export const ImpressionEvent = ({ eventName, context = {}, children }) => {
    const injectedEventName = injectParamsToField(eventName);
    const mixpanelEventContext = convertContextToMixpanel(context.mixpanel);
    const { context: managerContext } = useContext(EventReportingManagerContext);
    const [ref] = useImpressionItem({
        onImpression: () => sendMixpanelEvent(IMPRESSION_EVENT_NAME, managerContext, mixpanelEventContext),
        enrichment: merge(
            {
                type: injectedEventName,
                group: USER_IMPRESSIONS,
                action: { type: IMPRESSION_ACTION },
            },
            managerContext,
            convertContextToBQ(context.bq)
        ),
        sendImpressionToBQ: !!injectedEventName,
    });

    return renderChildren(children, { ref });
};
