import { loadable } from '@fiverr-private/perseus';
import { clickEventNameInput, componentNameInput, eventEnrichmentInput } from '../helpers/inputs';
import TagConfig from '../../../../docs/builderDataDisplayComponents/Tag.json';
import TagPrefixConfig from '../../../../docs/builderDataDisplayComponents/TagPrefix.json';
import TagCloseIconConfig from '../../../../docs/builderDataDisplayComponents/TagCloseIcon.json';
import TagImageConfig from '../../../../docs/builderDataDisplayComponents/TagImage.json';
import RatingConfig from '../../../../docs/builderDataDisplayComponents/Rating.json';
import withClick from '../helpers/withClick';
import { VERSIONS } from '../../../../constants';

const Tag = loadable(() => import(/* webpackChunkName: "Tag" */ './tag'));
Tag.displayName = 'Tag';

const TagPrefix = loadable(() => import(/* webpackChunkName: "Tag" */ './tagPrefix'));
TagPrefix.displayName = 'TagPrefix';

const TagCloseIcon = loadable(() => import(/* webpackChunkName: "Tag" */ './tagCloseIcon'));
TagCloseIcon.displayName = 'TagCloseIcon';

const TagImage = loadable(() => import(/* webpackChunkName: "Tag" */ './tagImage'));
TagImage.displayName = 'TagImage';

const Rating = loadable(() => import(/* webpackChunkName: "Rating" */ './rating'));
Rating.displayName = 'Rating';

const DATA_DISPLAY_COMPONENTS_CONFIG = {
    components: [
        { element: Tag, version: VERSIONS.V2, componentConfig: TagConfig, noWrap: false },
        { element: TagPrefix, componentConfig: TagPrefixConfig },
        {
            element: withClick(TagCloseIcon),
            componentConfig: TagCloseIconConfig,
            manualInputs: [clickEventNameInput, componentNameInput, eventEnrichmentInput],
        },
        { element: TagImage, componentConfig: TagImageConfig },
        { element: Rating, componentConfig: RatingConfig, noWrap: false },
    ],
    category: 'Data_display',
};

export default DATA_DISPLAY_COMPONENTS_CONFIG;
