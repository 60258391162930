import { loadable } from '@fiverr-private/perseus';
import { impressionEventNameInput, componentNameInput, eventEnrichmentInput } from '../helpers/inputs';
import ListConfig from '../../../../docs/builderListComponents/List.json';
import NumberedListConfig from '../../../../docs/builderListComponents/NumberedList.json';
import UnorderedListConfig from '../../../../docs/builderListComponents/UnorderedList.json';
import ListItemConfig from '../../../../docs/builderListComponents/ListItem.json';
import ListItemIconConfig from '../../../../docs/builderListComponents/ListItemIcon.json';

const List = loadable(() => import(/* webpackChunkName: "List" */ './list'));
List.displayName = 'List';

const NumberedList = loadable(() => import(/* webpackChunkName: "List" */ './numberedList'));
NumberedList.displayName = 'NumberedList';

const UnorderedList = loadable(() => import(/* webpackChunkName: "List" */ './unorderedList'));
UnorderedList.displayName = 'UnorderedList';

const ListItem = loadable(() => import(/* webpackChunkName: "List" */ './listItem'));
ListItem.displayName = 'ListItem';

const ListItemIcon = loadable(() => import(/* webpackChunkName: "List" */ './listItemIcon'));
ListItemIcon.displayName = 'ListItemIcon';

const LIST_COMPONENTS_CONFIG = {
    components: [
        {
            element: List,
            componentConfig: ListConfig,
            manualInputs: [impressionEventNameInput, componentNameInput, eventEnrichmentInput],
        },
        {
            element: UnorderedList,
            componentConfig: UnorderedListConfig,
            manualInputs: [impressionEventNameInput, componentNameInput, eventEnrichmentInput],
        },
        {
            element: NumberedList,
            componentConfig: NumberedListConfig,
            manualInputs: [impressionEventNameInput, componentNameInput, eventEnrichmentInput],
        },
        {
            element: ListItem,
            componentConfig: ListItemConfig,
            manualInputs: [impressionEventNameInput, componentNameInput, eventEnrichmentInput],
        },
        {
            element: ListItemIcon,
            componentConfig: ListItemIconConfig,
        },
    ],
    category: 'List',
};

export default LIST_COMPONENTS_CONFIG;
