import { loadable } from '@fiverr-private/perseus';
import DropdownConfig from '../../../../docs/builderDropdownComponents/Dropdown.json';
import DropdownAnchorConfig from '../../../../docs/builderDropdownComponents/DropdownAnchor.json';
import DropdownContentConfig from '../../../../docs/builderDropdownComponents/DropdownContent.json';
import DropdownTriggerConfig from '../../../../docs/builderDropdownComponents/DropdownTrigger.json';
import DropdownMenuConfig from '../../../../docs/builderDropdownComponents/DropdownMenu.json';
import MenuGroupConfig from '../../../../docs/builderDropdownComponents/MenuGroup.json';
import MenuItemConfig from '../../../../docs/builderDropdownComponents/MenuItem.json';
import MenuDividerConfig from '../../../../docs/builderDropdownComponents/MenuDivider.json';
import { BUILDER_COMPONENT_TYPE, BUILDER_ELEMENT, DEFAULT_TEXT_COMPONENT } from '../../../../constants';

const Dropdown = loadable(() => import(/* webpackChunkName: "Dropdown" */ './dropdown'));
Dropdown.displayName = 'Dropdown';

const DropdownAnchor = loadable(() => import(/* webpackChunkName: "Dropdown" */ './dropdownAnchor'));
DropdownAnchor.displayName = 'DropdownAnchor';

const DropdownContent = loadable(() => import(/* webpackChunkName: "Dropdown" */ './dropdownContent'));
DropdownContent.displayName = 'DropdownContent';

const DropdownTrigger = loadable(() => import(/* webpackChunkName: "Dropdown" */ './dropdownTrigger'));
DropdownTrigger.displayName = 'DropdownTrigger';

const DropdownMenu = loadable(() => import(/* webpackChunkName: "Dropdown" */ './dropdownMenu'));
DropdownMenu.displayName = 'DropdownMenu';

const MenuItem = loadable(() => import(/* webpackChunkName: "Dropdown" */ './menuItem'));
MenuItem.displayName = 'MenuItem';

const MenuGroup = loadable(() => import(/* webpackChunkName: "Dropdown" */ './menuGroup'));
MenuGroup.displayName = 'MenuGroup';

const MenuDivider = loadable(() => import(/* webpackChunkName: "Dropdown" */ './menuDivider'));
MenuDivider.displayName = 'MenuDivider';

const DROPDOWN_COMPONENTS_CONFIG = {
    components: [
        {
            element: Dropdown,
            componentConfig: DropdownConfig,
            manualOptions: {
                noWrap: false,
                defaultChildren: [
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: {
                                    name: 'fiverr:DropdownTrigger',
                                },
                            },
                        ],
                        component: {
                            name: 'fiverr:DropdownAnchor',
                        },
                    },
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: {
                                    name: 'fiverr:DropdownMenu',
                                },
                                children: [
                                    {
                                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                        component: {
                                            name: 'fiverr:MenuItem',
                                        },
                                        children: [
                                            {
                                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                                component: DEFAULT_TEXT_COMPONENT,
                                            },
                                        ],
                                    },
                                    {
                                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                        component: {
                                            name: 'fiverr:MenuItem',
                                        },
                                        children: [
                                            {
                                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                                component: DEFAULT_TEXT_COMPONENT,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                        component: {
                            name: 'fiverr:DropdownContent',
                        },
                    },
                ],
            },
        },
        {
            element: DropdownAnchor,
            componentConfig: DropdownAnchorConfig,
        },
        {
            element: DropdownContent,
            componentConfig: DropdownContentConfig,
        },
        {
            element: DropdownTrigger,
            componentConfig: DropdownTriggerConfig,
        },
        {
            element: DropdownMenu,
            componentConfig: DropdownMenuConfig,
        },
        {
            element: MenuItem,
            componentConfig: MenuItemConfig,
        },
        {
            element: MenuGroup,
            componentConfig: MenuGroupConfig,
        },
        {
            element: MenuDivider,
            componentConfig: MenuDividerConfig,
        },
    ],
    category: 'Dropdown',
};

export default DROPDOWN_COMPONENTS_CONFIG;
