import { loadable } from '@fiverr-private/perseus';
import { COMPONENT_PREFIX } from '../../../../constants';
import ModalConfig from '../../../../docs/builderOverlayComponents/Modal.json';

const ModalAdapter = loadable(() => import(/* webpackChunkName: "ModalAdapter" */ './ModalAdapter'));

export const MODAL_ADAPTER_CONFIG = {
    components: [
        {
            component: ModalAdapter,
            options: {
                name: `${COMPONENT_PREFIX}ModalAdapter`,
                inputs: ModalConfig.options.inputs,
                hideFromInsertMenu: true,
            },
            hasChildren: true,
        },
    ],
};
