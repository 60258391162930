import { loadable } from '@fiverr-private/perseus';
import { clickEventNameInput, componentNameInput, eventEnrichmentInput } from '../helpers/inputs';
import ButtonConfig from '../../../../docs/builderFitComponents/Button.json';
import AvatarConfig from '../../../../docs/builderFitComponents/Avatar.json';
import TagConfig from '../../../../docs/builderFitComponents/Tag.json';

const Button = loadable(() => import(/* webpackChunkName: "Button" */ './button'));
Button.displayName = 'Button';

const Avatar = loadable(() => import(/* webpackChunkName: "Avatar" */ './avatar'));
Avatar.displayName = 'Avatar';

const Tag = loadable(() => import(/* webpackChunkName: "Tag" */ './tag'));
Tag.displayName = 'Tag';

const FIT_COMPONENTS_CONFIG = {
    components: [
        {
            element: Button,
            componentConfig: ButtonConfig,
            manualInputs: [clickEventNameInput, componentNameInput, eventEnrichmentInput],
            manualOptions: { hideFromInsertMenu: true },
        },
        { element: Avatar, componentConfig: AvatarConfig, noWrap: false },
        { element: Tag, componentConfig: TagConfig, manualOptions: { hideFromInsertMenu: true } },
    ],
    category: 'Fit',
};

export default FIT_COMPONENTS_CONFIG;
