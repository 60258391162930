import { loadable } from '@fiverr-private/perseus';
import {
    clickEventNameInput,
    componentNameInput,
    eventEnrichmentInput,
    impressionEventNameInput,
} from '../helpers/inputs';
import TabGroupConfig from '../../../../docs/builderNavigationComponents/TabGroup.json';
import TabListConfig from '../../../../docs/builderNavigationComponents/TabList.json';
import TabPanelConfig from '../../../../docs/builderNavigationComponents/TabPanel.json';
import TabPanelsConfig from '../../../../docs/builderNavigationComponents/TabPanels.json';
import TabConfig from '../../../../docs/builderNavigationComponents/Tab.json';
import { COMPONENT_PREFIX, BUILDER_COMPONENT_TYPE, BUILDER_ELEMENT } from '../../../../constants';

const TabGroup = loadable(() => import(/* webpackChunkName: "TabGroup" */ './tabGroup'));
TabGroup.displayName = 'TabGroup';

const TabList = loadable(() => import(/* webpackChunkName: "TabGroup" */ './tabList'));
TabList.displayName = 'TabList';

const Tab = loadable(() => import(/* webpackChunkName: "TabGroup" */ './tab'));
Tab.displayName = 'Tab';

const TabPanels = loadable(() => import(/* webpackChunkName: "TabGroup" */ './tabPanels'));
TabPanels.displayName = 'TabPanels';

const TabPanel = loadable(() => import(/* webpackChunkName: "TabGroup" */ './tabPanel'));
TabPanel.displayName = 'TabPanel';

const NAVIGATION_COMPONENTS_CONFIG = {
    components: [
        {
            element: TabGroup,
            componentConfig: TabGroupConfig,
            manualOptions: {
                noWrap: false,
                defaultChildren: [
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [1, 2, 3].map((idx) => ({
                            [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                            component: {
                                name: 'fiverr:Tab',
                            },
                            children: [
                                {
                                    [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                    component: {
                                        name: `${COMPONENT_PREFIX}Text`,
                                        options: { text: `Tab ${idx}`, embed: true },
                                    },
                                },
                            ],
                        })),
                        component: {
                            name: 'fiverr:TabList',
                        },
                    },
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [1, 2, 3].map((idx) => ({
                            [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                            component: {
                                name: 'fiverr:TabPanel',
                            },
                            children: [
                                {
                                    [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                    component: {
                                        name: `${COMPONENT_PREFIX}Text`,
                                        options: { text: `Tab Panel ${idx}`, color: { default: 'inherit' } },
                                    },
                                },
                            ],
                        })),
                        component: {
                            name: 'fiverr:TabPanels',
                        },
                    },
                ],
            },
        },
        {
            element: TabList,
            componentConfig: TabListConfig,
        },
        {
            element: Tab,
            componentConfig: TabConfig,
            manualInputs: [clickEventNameInput, componentNameInput, eventEnrichmentInput],
            defaultChildren: [
                {
                    [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                    component: {
                        name: `${COMPONENT_PREFIX}Text`,
                        options: { text: `Tab`, embed: true },
                    },
                },
            ],
        },
        {
            element: TabPanels,
            componentConfig: TabPanelsConfig,
        },
        {
            element: TabPanel,
            componentConfig: TabPanelConfig,
            manualInputs: [componentNameInput, eventEnrichmentInput, impressionEventNameInput],
        },
    ],
    category: 'Navigation',
};

export default NAVIGATION_COMPONENTS_CONFIG;
