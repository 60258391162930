/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import { ClickEvent } from '../../../../components/ClickEvent';
import getEventContext from './getEventContext';

const withClick =
    (WrappedComponent) =>
    ({ eventName, reportingComponentName, eventEnrichment, ...props }) => {
        const componentName = reportingComponentName || WrappedComponent.displayName;

        return eventName ? (
            <ClickEvent context={getEventContext({ componentName, eventEnrichment, eventName })} eventName={eventName}>
                <WrappedComponent {...props} />
            </ClickEvent>
        ) : (
            <WrappedComponent {...props} />
        );
    };

export default withClick;
